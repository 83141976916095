<template>
  <nav style="height:10%" ref="navSize" class="navbar navbar-expand-lg navbar-light bg-light">
    <img
      src="./assets/img/gecad.png"
      alt="logo"
      style="width: 10%; padding-left: 1%; padding-right: 1%"
    />
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/documentation"
            >Documentation</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/tester">Tester</router-link>
        </li>
      </ul>
    </div>
  </nav>
  <router-view :navBarSize="navSize" :config="config" />
</template>

<script>
export default {
  data() {
    return {
      navSize: "",
      config: ""
    };
  },
  mounted() {
    this.navSize = this.$refs.navSize.clientHeight + "px";
    fetch('/config.json')
      .then(config => config.json())
      .then(data => this.config = data)
      .catch(err => console.log(err.message))
  },
};
</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400&display=swap');
#app {
  font-family: Barlow, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(29, 29, 29);
  font-weight: 500;
}


.no-scrollable{
  overflow: hidden;
}

h2 {
  color: #e10a0a;
  letter-spacing: 2px;
}
</style>
