import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config';
import './assets/styles/bootstrap.css';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import "./assets/styles/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import axios from 'axios'
import VueAxios from 'vue-axios'
import FileUpload from 'primevue/fileupload';
import InputNumber from 'primevue/inputnumber';
import SelectButton from 'primevue/selectbutton';


let app = createApp(App)
app.use(router)
app.use(PrimeVue, { ripple: true });
app.use(VueAxios, axios)
app.component('FileUpload', FileUpload)
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('Dropdown', Dropdown);
app.component('Textarea', Textarea);
app.component('Button', Button);
app.component('Panel', Panel);
app.component('InputNumber', InputNumber);
app.component('SelectButton', SelectButton);

app.mount('#app');