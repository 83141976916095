<template>
  <div :style="{ height: `calc(100% - ${navBarSize})` }">
    <div class="container h-100">
      <div class="row align-items-center h-100">
        <div class="col-sm-7">
          <h1>Welcome to the GECAD's Power Flow Service!</h1>
          <h3>The GECAD's Power Flow Service evaluates electrical grids.</h3>
        </div>
        <div class="col-sm-5">
          <p>
            The GECAD's Power Flow Service provides the creation and evaluation
            of electrical grids with the help of the
            <em
              ><a href="https://pandapower.readthedocs.io/en/v2.6.0/"
                >pandapower library (v2.6.0)</a
              ></em
            >.
          </p>
          <p>
            The PFS is a web service built with <em><a href="https://www.djangoproject.com/">Django</a></em>, that utilizes the
            pandapower library
            <em
              ><router-link style="text-decoration: none" to="/#end">
                [1]
              </router-link></em
            >
            to define and evaluate electrical networks. The pandapower consists
            of an open-source python tool capable of analyzing distribution and
            transmission systems
            <em
              ><router-link style="text-decoration: none" to="/#end"
                >[2]</router-link
              ></em
            >
            by using the power flow analysis to obtain the voltages at the
            buses, as well as the line flows and the system losses
            <em
              ><router-link style="text-decoration: none" to="/#end"
                >[3]</router-link
              ></em
            >. This service allows several types of requests using both JSON and
            Excel as input and output and allowing the definition of every
            element of an electrical network, as long as it exists in the
            pandapower library.
          </p>
          <p>
            The schemas built to validate the inputs of this service also allow
            different types of power flow algorithms, so that the user can
            choose the one that applies better to the scenario that needs to be
            tested. At last, the result type (JSON or Excel) can also be
            configured in the input, so that the user can choose the one that he
            is most comfortable with. This service provides several types of
            requests, which are well documented in the
            <em
              ><router-link
                style="color: #e10a0a; text-decoration: none"
                to="/documentation"
                >Documentation</router-link
              ></em
            >
            section.
          </p>
        </div>
      </div>
    </div>
    <div style="text-align: left; padding-bottom: 2%" class="container">
      <h3>References</h3>
      <ul>
        <li>
          [1] “pandapower — pandapower 2.6.0 documentation.” [Online].
          Available:
          <a href="https://pandapower.readthedocs.io/en/v2.6.0/"
            >https://pandapower.readthedocs.io/en/v2.6.0/</a
          >.
        </li>
        <li>
          [2] L. Thurner et al., "Pandapower—An Open-Source Python Tool for
          Convenient Modeling, Analysis, and Optimization of Electric Power
          Systems," in IEEE Transactions on Power Systems, vol. 33, no. 6, pp.
          6510-6521, Nov. 2018.<a href="https://doi.org/10.1109/TPWRS.2018.2829021"> 10.1109/TPWRS.2018.2829021</a>.
        </li>
        <li id="end">
          [3] Mohammed Albadi (March 1st 2019). Power Flow Analysis, Computational
          Models in Engineering, Konstantin Volkov, IntechOpen.
          <a href="https://doi.org/10.5772/intechopen.83374"
            >10.5772/intechopen.83374</a
          >.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {
    navBarSize: Number,
  },
  methods: {
    scrollToEnd() {
      this.viewportScroller.scrollToAnchor("end");
    },
  },
  components: {},
};
</script>

<style scoped>
h1 {
  color: #e10a0a;
  padding-bottom: 2%;
}

h3 {
  text-align: left;
}

a {
  color: #e10a0a;
  text-decoration: none;
}

a:hover {
  color: #e10a0a;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: #e10a0a;
  text-decoration: none;
}

.router-link-active {
    color: #e10a0a !important;
    border-bottom: none !important;
}
</style>
